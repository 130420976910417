:root {
  --navbar-size: 50px;
  --navbar-with-filters-size: 50px;
}

body {
  margin: 0;
  padding: 0;
  font-feature-settings: "liga", "kern";
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

/* .limited table td, */
.limited .mantine-Badge-inner,
span.limited,
.limited code,
.limited table .mantine-Text-root {
  filter: blur(4px);
  text-overflow: ellipsis;
}

.limited .recharts-responsive-container {
  filter: blur(7px);
}

.unblockable pre,
.unblockable code,
.unblockable .mantine-Badge-inner,
.unblockable .mantine-Text-root,
.unblockable .recharts-responsive-container {
  filter: none !important;
}

.mantine-Modal-inner {
  /* fix modal unaligned content */
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mantine-Badge-root {
  max-width: 100%;
}
